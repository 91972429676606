import { TFunction } from "i18next";
import { Locale as HygraphLocale } from "../../hygraph/vo";

enum Locale {
  German = "de",
  French = "fr",
  Italian = "it",
}

export const mapHygraphLocale = (locale: HygraphLocale) => {
  return locale as unknown as Locale;
};

export const LOCALE_TRANSLATORS: Record<Locale, (t: TFunction) => string> = {
  [Locale.German]: t => t("GERMAN"),
  [Locale.French]: t => t("FRENCH"),
  [Locale.Italian]: t => t("ITALIAN"),
};

export default Locale;
